/**
* Caution: This file shouldn't be imported by any component
**/

@use '@angular/material' as mat;
@import '@angular/material/theming';
@import '../base';
/* stylelint-disable */
@import '@lacapitale/beneva-design-system/src/scss/_beneva-theme';
/* stylelint-enable */
@include mat.core();
@include mat.legacy-core(); // this is the important line

$md-palette-primary-beneva: mat.define-palette($md-primary-beneva);
$md-palette-accent-beneva: mat.define-palette($md-primary-beneva);
$md-palette-warn-beneva: mat.define-palette($md-warn-beneva);
$bnv-mat-button: mat.define-typography-level(
  $font-size: 1rem,
  $line-height: 1.375rem,
  $font-family: 'matter-semibold',
);

$bnv-mat-input: mat.define-typography-level(
  $font-family: 'matter-regular',
  $font-size: 1rem,
  $line-height: 1,
  $letter-spacing: 'normal',
);

$bnv-typography: mat.define-typography-config(
  $font-family: 'matter-regular',
  $button: $bnv-mat-button,
);

$md-theme-beneva: mat.define-light-theme(
  (
    color: (
      primary: $md-palette-primary-beneva,
      accent: $md-palette-accent-beneva,
      warn: $md-palette-warn-beneva,
    ),
    typography: $bnv-typography,
  )
);

@include mat.all-component-themes($md-theme-beneva);
@include mat.all-legacy-component-themes($md-theme-beneva);

body {
  font-family: 'matter-regular', sans-serif;
  font-size: 1rem;
  line-height: normal;
  /* stylelint-disable-next-line */
  @import '@lacapitale/beneva-design-system/src/scss/beneva';

  .wave-bg {
    background: $primary url('/assets/img/wave.svg') no-repeat;
    background-size: 120% auto;
    background-position: center 120px;

    @media (max-width: 1200px) {
      background-size: 250% auto;
      background-position: 60% 160px;
    }

    @media (max-width: 992px) {
      background-size: 260% auto;
      background-position: 60% 120px;
    }

    @media (max-width: 768px) {
      background-size: 250% auto;
      background-position: 60% 95px;
    }
  }

  .wave2-bg,
  .wave-grey-bg {
    background-image: url('/assets/img/wave2.svg');
    background-size: 120% auto;
    background-position: center 120px;
    background-repeat: no-repeat;

    &.home-page {
      background-position: center 190px;
      background-attachment: fixed;
    }

    @media (max-width: 1200px) {
      background-size: 250% auto;
      background-position: 60% 160px;

      &.home-page {
        background-position: 75% 180px;
      }
    }

    @media (max-width: 992px) {
      background-size: 260% auto;
      background-position: 60% 120px;

      &.home-page {
        background-position: 90% 160px;
      }
    }

    @media (max-width: 576px) {
      &.home-page {
        background-size: 450% auto;
        background-position: 80% 160px;
      }
    }
  }

  .wave-grey-bg {
    background-image: url('/assets/img/grey-wave.svg');
  }
}

.mat-progress-bar {
  &.bg-white {
    .mat-progress-bar-buffer {
      background-color: white;
    }
  }
}

.lc-theme {
  /* mat-select-panel */
  &.mat-select-panel {
    &.lc-select-panel-auto {
      .mat-option {
        height: auto;
        white-space: normal;
        min-height: 3rem;
      }
    }
  }

  /* Button */

  .mat-button-focus-overlay {
    background-color: $blueLink;
  }

  /* mat-form-field */
  .mat-form-field {
    /* mat-form-field with appearance=outline */
    line-height: 1.3;

    &.mat-form-field-appearance-outline {
      .mat-form-field-flex {
        &:hover,
        &:focus {
          .mat-form-field-outline {
            &.mat-form-field-outline-thick {
              color: $blueLink;

              .mat-form-field-outline-gap,
              .mat-form-field-outline-start,
              .mat-form-field-outline-end {
                border-width: 2px;
              }
            }

            .mat-form-field-outline-start {
              width: lc-spacing(2) !important;
            }
          }

          .mat-form-field-label {
            mat-label {
              color: $blueLink;
            }
          }
        }

        .mat-form-field-outline {
          color: $black;

          .mat-form-field-outline-gap,
          .mat-form-field-outline-start,
          .mat-form-field-outline-end {
            border-width: 1px;
          }

          .mat-form-field-outline-start {
            width: lc-spacing(2) !important;
          }
        }
      }

      .mat-form-field-wrapper {
        padding: 0;

        .mat-form-field-label {
          left: lc-spacing(1);
        }
      }

      .mat-form-field-infix {
        padding: lc-spacing(1);
        border-top: 0.6em solid transparent;
      }

      /* mat-select into mat-form-field outline */
      .mat-select {
        .mat-select-arrow-wrapper {
          transform: none;
        }
      }
    }

    &.w-100-mobile {
      @include media-breakpoint-down(sm) {
        width: 100%;
        padding: 0;
      }
    }
  }

  /* Textarea with count */
  .textarea-with-count {
    position: relative;

    .count {
      position: absolute;
      bottom: 0;
      right: 0;
      color: $fontClassic;
    }
  }

  /* Dialog - dialog.scss has similar styles, not sure about the needs of it here */
  .cdk-overlay-pane {
    @include media-breakpoint-down(sm) {
      &.large-modal-with-action,
      &.dialog-panel {
        max-width: 100vw;
        width: 100%;
        height: 100%;
      }
    }

    &.not-action {
      h2 {
        padding: lc-spacing(2) lc-spacing(2) 0 lc-spacing(2);
        margin-bottom: 0;
      }

      mat-dialog-actions {
        margin-bottom: 0;
        padding-bottom: 0;

        @include media-breakpoint-down(sm) {
          margin-top: 0;
        }
      }
    }

    &.large-modal-with-action {
      mat-dialog-container {
        @include mat-elevation(24);

        padding: lc-spacing(2);

        h2 {
          font-size: $lc-subheading;
          margin-bottom: lc-spacing(2);
          color: rgba($black, 0.75);
          line-height: $lc-subheading-lineheight;
        }

        mat-divider {
          margin-right: -(lc-spacing(2));
          margin-left: -(lc-spacing(2));
        }

        mat-dialog-content {
          padding: lc-spacing(2) lc-spacing(2) 0 lc-spacing(2);
          margin: 0;

          h3 {
            margin: 0;
            font-weight: 600;
          }

          p,
          h3 {
            font-size: $lc-body2;
            color: rgba($black, 0.6);
          }

          @include media-breakpoint-down(sm) {
            height: calc(100% - 150px);
            max-height: initial;
          }
        }

        mat-dialog-actions {
          display: flex;
          margin: lc-spacing(1) lc-spacing(2) lc-spacing(3) 0;

          @include media-breakpoint-down(sm) {
            margin-right: 0;
            margin-top: lc-spacing(3);
            justify-content: center;
          }

          button {
            margin-left: auto;

            @include media-breakpoint-down(sm) {
              margin: auto;
            }
          }
        }
      }
    }
  }

  .flow-dialog {
    max-height: 500px;
    height: 100%;

    .mat-horizontal-stepper-header-container {
      display: none;
    }

    .mat-dialog-container {
      padding: 0;

      hr {
        margin-left: -(lc-spacing(2));
        margin-right: -(lc-spacing(2));
      }

      .mat-dialog-actions {
        margin-bottom: 0;
      }
    }

    .mat-button-wrapper {
      display: flex;

      .mat-spinner {
        margin: lc-spacing(1) 0 0 lc-spacing(1);
      }
    }

    .with-product {
      span {
        white-space: nowrap;
      }

      @include media-breakpoint-down(sm) {
        span {
          white-space: normal;
        }
      }
    }
  }

  &.lc-modal-fullsize {
    max-width: 100% !important;
    max-height: 100%;
    width: 100%;
    height: 100%;

    .mat-dialog-container {
      margin: 0;
      padding: 0;
    }

    &.lc-claim-modal {
      .mat-dialog-container {
        padding-top: 0;
      }
    }
  }

  .lc-chip {
    display: inline-block;
    color: $greyBlack;
    background: transparent;
    border-radius: 5px;
    line-height: 0.7rem;
    font-size: 0.625rem;
    padding: 0 0.3rem;
    height: 12px;
    font-weight: $font-weight-semibold;

    &.yellow {
      background: $lcYellowDark;
    }
  }

  /* lc-mat-menu */
  &.lc-mat-menu {
    .mat-menu-content:not(:empty) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

/* Material Opened Select theme override */
.mat-select-panel-wrap {
  .mat-option {
    .mat-option-text {
      line-height: normal;
      white-space: normal;
    }
  }
}

/* Material snackbar theme override */
.cdk-overlay-container {
  .cdk-overlay-pane {
    snack-bar-container {
      &.mat-snack-bar-container {
        margin-bottom: 94px;
      }
    }
  }
}

// Créer l'histoire pour mettre par défaut text-decoration: underline pour <a>;
// et enlever les lignes de code suivantes:
.link-underline {
  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
