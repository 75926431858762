@import './mixins';
@import '@lacapitale/beneva-design-system/src/scss/helpers/colors-beneva';
@import '@lacapitale/beneva-design-system/src/scss/helpers/variables';
@import './variables';

html {
  height: 100%;
}

body {
  height: 100%;
  background: $background-body;

  @include height-100;

  // new styles

  h1,
  h2,
  h3,
  h4,
  h5 {
    &.bnv-subheading2 {
      font-size: $_26;
      line-height: $_30;
    }

    &.bnv-subheading4 {
      font-size: $_20;
      line-height: $_22;
      color: $title-table;
    }

    &.bnv-subheading5 {
      font-size: $_22;
      line-height: $_24;
    }
  }

  mat-card {
    &.bnv-card {
      color: $title-table;

      .bnv-caption-normal {
        color: $title-table;
      }

      .but-link {
        &:hover {
          .mat-button-wrapper,
          .mdc-button__label {
            span {
              text-decoration: none;
            }
          }
        }

        .mat-button-wrapper,
        .mdc-button__label {
          span {
            text-decoration: underline;
          }
        }
      }

      .mat-radio-label-content,
      .mat-radio-outer-circle {
        color: $title-table;
      }
    }
  }

  .bnv-container {
    max-width: 51.25rem;
  }

  .bnv-button-back {
    > span {
      display: flex;
      align-items: center;

      mat-icon {
        margin-right: 0.4rem;
      }
    }
  }

  // buttons
  .mdc-button.bnv-btn-icon {
    font-size: $_16;
    line-height: $_16;
    letter-spacing: -0.5px;
    width: 100%;
    max-width: 25rem;
    min-height: $_60;
    padding: $_16 $_24;
    height: auto;
    border-radius: 6.25rem;
    justify-content: space-between;

    mat-icon {
      width: $_18;
      height: $_18;
      flex-shrink: 0;
    }

    .mat-button-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &:hover {
      div {
        color: #fff;
      }

      mat-icon {
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }

  .mat-expansion-panel .mat-expansion-panel-header {
    height: auto;
    min-height: auto;
  }
}

.main-maintenance-container,
lc-root,
lc-layout,
lc-home,
lc-inbox,
lib-adjudication,
bnv-claim-submission,
lc-error404,
lc-error500 {
  @include height-100;
}

.main-container .lc-maintenance-container {
  display: flex;
  flex: 1 0 auto;

  > * {
    width: 100%;
  }

  > router-outlet {
    width: auto;
  }
}

a.external-link {
  &:hover {
    text-decoration: none;

    .hover-state {
      text-decoration: underline;
    }
  }

  &.underline-link {
    .hover-state {
      text-decoration: underline;
    }

    &:hover {
      .hover-state {
        text-decoration: none;
      }
    }
  }
}

.notice-external-link {
  text-decoration: none;

  .underline {
    text-decoration: underline;
  }

  &:hover {
    text-decoration: none;

    .underline {
      text-decoration: none;
    }
  }

  i {
    font-size: 1rem;
    line-height: 1.2rem;
    margin: 0 0 0 0.3rem;
  }
}

.mat-form-field-label .mat-form-field-required-marker {
  display: none;
}

.btn-popper {
  color: $primary;

  &:focus,
  &:hover {
    color: $primary-sombre;
  }
}

.login-launch-icon {
  transform: translateY(4px);
  font-size: 1rem;
}

.mat-icon[svgicon] {
  overflow: visible;
}

.white-text {
  color: $white;

  a {
    &:hover {
      color: $white;
    }
  }
}

// Hide the default dropzone message and button
.dropzone-wrapper {
  .dz-default, .dz-message {
    display: none;
  }
}

