@mixin reclaim($reclaim-color, $reclaim-icon-color) {
  .reclaim-info {
    position: relative;
    width: 100%;

    i {
      font-size: 2rem;
      line-height: 2rem;

      &::before {
        color: $reclaim-icon-color;
      }
    }

    .reclaim-info-content {
      background: $reclaim-color;
    }
  }
}

body {
  @include reclaim(
    #ECF4FB 0% 0% no-repeat padding-box,
    #88BAE5
  );
}
