@import '@lacapitale/beneva-design-system/src/scss/helpers/colors-beneva';

.flow-dialog {
  .mat-horizontal-stepper-header-container {
    display: none;
  }
}

.dialog-panel {
  .mat-horizontal-content-container {
    padding: 0;
  }

  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .mat-spinner {
      margin: lc-spacing(1) 0 0 lc-spacing(1);
    }
  }
}

.modal-redirection {
  mat-dialog-container.mat-dialog-container,
  mat-dialog-container.mat-mdc-dialog-container {
    padding: 0;
    margin: 0;

    .mat-mdc-dialog-surface {
      padding: 0;
    }
  }

  mat-dialog-container.mat-mdc-dialog-container {
    background: $primary;
    color: $white;
  }
}

.modal-redirection.cdk-overlay-pane {
  &.dialog-bottom {
    position: absolute !important;
    bottom: 30px;
  }
}

.dialog-panel.cdk-overlay-pane {
  &.lc-dialog {
    padding: 0;

    mat-dialog-container.mat-dialog-container {
      padding: 0;
      margin: 0;

      .mat-dialog-title {
        text-align: left;
        padding: 1.8rem 1.5rem;
        margin: 0;
        background-color: $titleBackground;
        font-size: 1.6225rem;
        font-weight: normal;
        color: $greyBlack;
      }

      .mat-dialog-content {
        padding: 1.8rem 1.5rem;
        margin: 0;
      }

      .mat-dialog-actions {
        margin: 0;
        padding: 1.8rem 1.5rem;
      }
    }
  }

  &.tos-modal {
    .mat-dialog-container {
      padding: 0;

      @include media-breakpoint-up(md) {
        top: 30px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    max-width: 100vw !important;
    width: 100% !important;
    height: 100%;

    &.mobile-centered {
      height: auto;
    }

    mat-dialog-container.mat-dialog-container {
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;

      .mat-dialog-content {
        max-height: none;
      }
    }
  }
}

mat-dialog-container.mat-dialog-container {
  position: relative;

  .close-button {
    position: absolute;
    right: 0.8rem;
    font-size: 1.7rem;
    top: 0.8rem;
    cursor: pointer;

    button {
      &:focus {
        outline: 0;
      }
    }

    @include media-breakpoint-down(sm) {
      right: 0;
    }
  }
}

.with-person mat-dialog-container.mat-dialog-container {
  overflow: visible;
  padding-top: 40px;

  .person-img {
    width: 5rem;
    position: absolute;
    left: calc(50% - 2.5rem);
    top: -4rem;

    @include media-breakpoint-down(sm) {
      position: static;
      margin: 0 auto;
      display: block;
    }
  }
}

.mat-datepicker-dialog {
  .mat-dialog-container {
    height: auto !important;
  }
}
