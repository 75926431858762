@import "../colors";

/* stylelint-disable */
@font-face {
  font-family: 'coverages';
  src: url('/assets/fonts/coverages.eot?1octla');
  src:
    url('/assets/fonts/coverages.eot?1octla#iefix') format('embedded-opentype'),
    url('/assets/fonts/coverages.ttf?1octla') format('truetype'),
    url('/assets/fonts/coverages.woff?1octla') format('woff'),
    url('/assets/fonts/coverages.svg?1octla#coverages') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="coverages-"],
 [class*=" coverages-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'coverages' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  // Ne pas oublier de commenter lorsqu'on met a jour les icones
  // line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.coverages-icon-expenses::before {
  content: "\e900";
}
.coverages-icon-wind::before {
  content: "\e901";
}
.coverages-icon-hail::before {
  content: "\e902";
}
.coverages-icon-default::before {
  content: "\e903";
}
.coverages-icon-car-alarm::before {
  content: "\e904";
}
.coverages-icon-legal::before {
  content: "\e905";
}
.coverages-icon-condition::before {
  content: "\e906";
}
.coverages-icon-all-risks::before {
  content: "\e907";
}
.coverages-icon-wreck::before {
  content: "\e908";
}
.coverages-icon-expenses2::before {
  content: "\e909";
}
.coverages-icon-airmedic::before {
  content: "\e90a";
}
.coverages-icon-faq19::before {
  content: "\e90b";
}
.coverages-icon-faq13c::before {
  content: "\e90c";
}
.coverages-icon-fire::before {
  content: "\e90d";
}
.coverages-icon-pardon::before {
  content: "\e90e";
}
.coverages-icon-faq2::before {
  content: "\e90f";
}
.coverages-icon-modification-amount::before {
  content: "\e913";
}
.coverages-icon-deceased::before {
  content: "\e914";
}
.coverages-icon-travel::before {
  content: "\e915";
}
.coverages-icon-storage-out::before {
  content: "\e916";
}
.coverages-icon-storage-in::before {
  content: "\e917";
}
.coverages-icon-umbrella::before {
  content: "\e910";
}
