@import '../colors';

/* stylelint-disable */
@font-face {
  font-family: 'icomoon';
  src: url('/assets/fonts/icomoon.eot?lcgchh');
  src:
    url('/assets/fonts/icomoon.eot?lcgchh#iefix') format('embedded-opentype'),
    url('/assets/fonts/icomoon.ttf?lcgchh') format('truetype'),
    url('/assets/fonts/icomoon.woff?lcgchh') format('woff'),
    url('/assets/fonts/icomoon.svg?lcgchh#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  // Ne pas oublier de commenter lorsqu'on met a jour les icones
  // line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-action-calendar::before {
  content: '\e900';
  color: $softBlack;
}

.icon-action-delete::before {
  content: '\e901';
  color: $softBlack;
}

.icon-action-email::before {
  content: '\e902';
  color: $softBlack;
}

.icon-action-favorite::before {
  content: '\e903';
  color: $softBlack;
}

.icon-action-filter::before {
  content: '\e904';
  color: $softBlack;
}

.icon-action-folder::before {
  content: '\e905';
  color: $softBlack;
}

.icon-action-gear::before {
  content: '\e906';
  color: $softBlack;
}

.icon-action-hamburger::before {
  content: '\e907';
  color: $softBlack;
}

.icon-action-mobile::before {
  content: '\e908';
  color: $softBlack;
}

.icon-action-notification::before {
  content: '\e909';
  color: $softBlack;
}

.icon-action-plus::before {
  content: '\e90a';
  color: $softBlack;
}

.icon-action-search::before {
  content: '\e90b';
  color: $softBlack;
}

.icon-arrow-collective::before {
  content: '\e90c';
  color: $softBlack;
}

.icon-arrow-down::before {
  content: '\e90d';
  color: $softBlack;
}

.icon-arrow-left::before {
  content: '\e90e';
  color: $softBlack;
}

.icon-arrow-next::before {
  content: '\e90f';
  color: $softBlack;
}

.icon-arrow-previous::before {
  content: '\e910';
  color: $softBlack;
}

.icon-arrow-right::before {
  content: '\e911';
  color: $softBlack;
}

.icon-arrow-up::before {
  content: '\e912';
  color: $softBlack;
}

.icon-attached::before {
  content: '\e913';
  color: $softBlack;
}

.icon-baby::before {
  content: '\e914';
  color: $softBlack;
}

.icon-car::before {
  content: '\e915';
  color: $softBlack;
}

.icon-cash::before {
  content: '\e916';
  color: $softBlack;
}

.icon-chalet::before {
  content: '\e917';
  color: $softBlack;
}

.icon-checkmark::before {
  content: '\e918';
  color: $softBlack;
}

.icon-collective::before {
  content: '\e919';
  color: $softBlack;
}

.icon-contact-support::before {
  content: '\e91a';
  color: $darkGrey;
}

.icon-edit::before {
  content: '\e91b';
  color: $darkGrey;
}

.icon-error-shield::before {
  content: '\e91c';
  color: $softBlack;
}

.icon-error::before {
  content: '\e91d';
  color: $softBlack;
}

.icon-fb-circle::before {
  content: '\e91e';
  color: $darkGrey;
}

.icon-filter::before {
  content: '\e91f';
  color: $softBlack;
}

.icon-finance::before {
  content: '\e920';
  color: $softBlack;
}

.icon-garage::before {
  content: '\e921';
  color: $softBlack;
}

.icon-gear::before {
  content: '\e922';
  color: $softBlack;
}

.icon-glasses::before {
  content: '\e923';
  color: $darkGrey;
}

.icon-hamburger-menu::before {
  content: '\e924';
  color: $darkGrey;
}

.icon-hearing-device::before {
  content: '\e925';
  color: $softBlack;
}

.icon-heart-2::before {
  content: '\e926';
  color: $softBlack;
}

.icon-heart::before {
  content: '\e927';
  color: $softBlack;
}

.icon-hospital::before {
  content: '\e928';
  color: $softBlack;
}

.icon-house-2::before {
  content: '\e929';
  color: $softBlack;
}

.icon-house-cash::before {
  content: '\e92a';
  color: $softBlack;
}

.icon-house::before {
  content: '\e92b';
  color: $softBlack;
}

.icon-interet::before {
  content: '\e92c';
  color: $softBlack;
}

.icon-linkedin-circle::before {
  content: '\e92d';
  color: $darkGrey;
}

.icon-massage::before {
  content: '\e92e';
  color: $softBlack;
}

.icon-notifications-fill::before {
  content: '\e92f';
  color: $darkGrey;
}

.icon-nurse::before {
  content: '\e930';
  color: $softBlack;
}

.icon-piggy-bank::before {
  content: '\e931';
  color: $softBlack;
}

.icon-play::before {
  content: '\e932';
  color: $softBlack;
}

.icon-pret::before {
  content: '\e933';
  color: $softBlack;
}

.icon-scroll-left::before {
  content: '\e934';
  color: $darkGrey;
}

.icon-scroll-right::before {
  content: '\e935';
  color: $darkGrey;
}

.icon-search::before {
  content: '\e936';
  color: $softBlack;
}

.icon-success::before {
  content: '\e937';
  color: $softBlack;
}

.icon-three-dots::before {
  content: '\e938';
  color: $softBlack;
}

.icon-tooth::before {
  content: '\e939';
  color: $softBlack;
}

.icon-travel-insurance::before {
  content: '\e93a';
  color: $softBlack;
}

.icon-tw-circle::before {
  content: '\e93b';
  color: $darkGrey;
}

.icon-dollar-flower::before {
  content: '\e93c';
  color: $softBlack;
}

.icon-house-3::before {
  content: '\e93d';
  color: $softBlack;
}

.icon-email::before {
  content: '\e93e';
  color: $softBlack;
}

.icon-speech::before {
  content: '\e93f';
  color: $softBlack;
}

.icon-camera::before {
  content: '\e940';
  color: $softBlack;
}

.icon-bell::before {
  content: '\e941';
  color: $softBlack;
}

.icon-power::before {
  content: '\e942';
  color: $softBlack;
}

.icon-arrow-flip::before {
  content: '\e943';
  color: $softBlack;
}

.icon-cancel::before {
  content: '\e944';
  color: $softBlack;
}

.icon-warning::before {
  content: '\e945';
  color: $softBlack;
}

.icon-compte-sante-2::before {
  content: '\e946';
  color: $softBlack;
}

.icon-compte-sante-1::before {
  content: '\e947';
  color: $softBlack;
}

.icon-download-arrow::before {
  content: '\e948';
  color: $softBlack;
}

.icon-download-box::before {
  content: '\e949';
  color: $softBlack;
}

.icon-download::before {
  content: '\e94a';
  color: $softBlack;
}

.icon-arrow-left-circled::before {
  content: '\e94b';
  color: $softBlack;
}

.icon-ext-link::before {
  content: '\e951';
}

.icon-white-file::before {
  content: '\e958';
  color: $softBlack;
}

.icon-file-png::before {
  content: '\e959';
  color: $softBlack;
}

.icon-file-jpg::before {
  content: '\e95a';
  color: $softBlack;
}

.icon-white-person::before {
  content: '\e95b';
  color: $softBlack;
  font-size: 1.2rem;
}

.icon-white-hourglass::before {
  content: '\e95c';
  color: $softBlack;
}

.icon-white-meds::before {
  content: '\e95d';
  color: $softBlack;
}

.icon-type-glasses::before {
  content: '\e95e';
  color: $softBlack;
}

.icon-white-dental::before {
  content: '\e95f';
  color: $softBlack;
  margin-left: -1px;
}

.icon-trash::before {
  content: '\e960';
  color: $softBlack;
}

.icon-argent::before {
  content: '\e961';
  color: $softBlack;
}

.icon-cochon::before {
  content: '\e964';
  color: $softBlack;
}

.icon-crochet1::before {
  content: '\e966';
  color: $softBlack;
}

.icon-crochet2::before {
  content: '\e967';
  color: $softBlack;
}

.icon-document::before {
  content: '\e969';
  color: $softBlack;
}

.icon-entrepreneure::before {
  content: '\e96a';
  color: $softBlack;
}

.icon-epargne::before {
  content: '\e96c';
  color: $softBlack;
}

.icon-fond::before {
  content: '\e96d';
  color: $softBlack;
}

.icon-hypotheque::before {
  content: '\e96e';
  color: $softBlack;
}

.icon-mainArgent::before {
  content: '\e971';
  color: $softBlack;
}

.icon-parapluie::before {
  content: '\e976';
  color: $softBlack;
}

.icon-roulotteTravail::before {
  content: '\e979';
  color: $softBlack;
}

.icon-default::before,
.icon-affa::before,
.icon-voiture::before {
  content: '\e97d';
  color: $softBlack;
}

.icon-info::before {
  content: '\e97e';
  color: $softBlack;
}

.icon-warning2::before {
  content: '\e97f';
  color: $lcWarning;
}

.icon-micro::before {
  content: '\e980';
}

.icon-pdf::before {
  content: '\e981';
}

.icon-exclusion::before {
  content: '\e982';
  color: $error;
}

.icon-couple::before {
  content: '\e983';
}

.icon-maisonauto::before {
  content: '\e984';
}

.icon-parapluie2::before {
  content: '\e985';
}

.icon-star::before {
  content: '\e986';
  color: $darkGrey;
}

.icon-star-empty::before {
  content: '\e987';
}

.icon-star-full::before {
  content: '\e988';
}

.icon-soumission::before {
  content: '\e989';
}

.icon-facture::before {
  content: '\e98a';
}

.icon-question-mark::before {
  content: '\e98b';
}

.icon-confirm::before {
  content: '\e98c';
}

.icon-success::before {
  content: '\e98d';
  color: #b9d300;
}

.icon-flip::before {
  content: '\e98e';
  color: $blueLink;
}

.icon-file::before {
  content: '\e98f';
  color: $blueLink;
}

.icon-man::before {
  content: '\e990';
  color: $softBlack;
}

.icon-phone::before {
  content: '\e991';
  color: $softBlack;
}

.icon-cell::before {
  content: '\e992';
  color: $softBlack;
}

.icon-envelop::before {
  content: '\e993';
  color: $softBlack;
}

.icon-shield-check::before {
  content: '\e994';
  color: $softBlack;
}

.icon-contrat-vert::before {
  content: '\e995';
  color: $softBlack;
}

.icon-action-plus-fill::before {
  content: '\e99a';
  color: $softBlack;
}

.icon-img::before {
  content: '\e999';
  color: $softBlack;
}

.icon-tools::before {
  content: '\e99b';
  color: $softBlack;
}

.icon-bill::before {
  content: '\e99c';
  color: $softBlack;
}

.icon-enterprises::before {
  content: '\e99d';
  color: $softBlack;
}

.icon-hsa::before {
  content: '\e94c';
  color: $softBlack;
}

.icon-ver::before {
  content: '\e94d';
  color: $softBlack;
}

.icon-medicaments::before {
  content: '\e94e';
  color: $softBlack;
}

.icon-dentaire::before {
  content: '\e94f';
  color: $softBlack;
}

.icon-vue::before {
  content: '\e950';
  color: $softBlack;
}

.icon-invalidite::before {
  content: '\e952';
  color: $softBlack;
}

.icon-pae::before {
  content: '\e953';
  color: $softBlack;
}

.icon-voyage::before {
  content: '\e954';
  color: $softBlack;
}

.icon-computer-cash::before {
  content: '\e955';
  color: $softBlack;
}

.icon-gift::before {
  content: '\e956';
  color: $softBlack;
}

.icon-car-flower::before {
  content: '\e957';
  color: $softBlack;
}

.icon-clock::before {
  content: '\e99e';
  color: $softBlack;
}

.icon-finance-increase::before {
  content: '\e99f';
  color: $softBlack;
}

.icon-cadenas::before {
  content: '\e9a0';
  color: $softBlack;
}

.icon-idea::before {
  content: '\e9a1';
  color: $softBlack;
}

.icon-conversation-inverse::before {
  content: '\e9a2';
  color: $softBlack;
}

.icon-conversation::before {
  content: '\e9a3';
  color: $softBlack;
}

.icon-calendrier::before {
  content: '\e9a7';
  color: $softBlack;
}

.icon-ajout-conducteur::before {
  content: '\e9a8';
  color: $softBlack;
}

.icon-collision::before {
  content: '\e9a9';
  color: $softBlack;
}

.icon-checklist::before {
  content: '\e9aa';
  color: $softBlack;
}

.icon-contact::before {
  content: '\e9ab';
  color: $softBlack;
}

.icon-quickquotes::before {
  content: '\e9ac';
  color: $softBlack;
}

.icon-sun::before {
  content: '\e9ad';
}

.icon-moon::before {
  content: '\e9ae';
}

.icon-mid-sun::before {
  content: '\e9af';
}

.icon-circum-COLLISION::before {
  content: '\e9b0';
}

.icon-circum-HIT_AND_RUN::before {
  content: '\e9b1';
}

.icon-circum-VANDALISM::before {
  content: '\e9b2';
}

.icon-circum-THEFT::before {
  content: '\e9b3';
}

.icon-circum-FIRE::before {
  content: '\e9b4';
}

.icon-circum-HAIL_OR_WIND::before {
  content: '\e9b5';
}

.icon-circum-GLASS_BREAKAGE::before {
  content: '\e9ca';
}

.icon-circum-OTHER::before {
  content: '\e9b6';
}

.icon-col-OTHER_VEHICLE::before {
  content: '\e9b7';
}

.icon-col-PEDESTRIAN::before {
  content: '\e9b8';
}

.icon-col-CYCLIST::before {
  content: '\e9b9';
}

.icon-col-ANIMAL::before {
  content: '\e9ba';
}

.icon-col-OBJECT_OR_BUILDING::before {
  content: '\e9bb';
}

.icon-col-NO_OTHER_VEHICLE::before {
  content: '\e9bc';
}

.icon-search-2::before {
  content: '\e9bd';
  color: $darkGrey;
}

.icon-step-done::before {
  content: '\e9be';
}

.icon-check-mark::before {
  content: '\e9bf';
  color: $darkGrey;
}

.icon-trombone::before {
  content: '\e9c0';
  color: $darkGrey;
}

.icon-add-claim-disability::before {
  content: '\e9c1';
  color: $softBlack;
}

.icon-modify-claim-disability::before {
  content: '\e9c2';
  color: $softBlack;
}

.icon-enterprise::before,
.icon-organisation::before {
  content: '\e9cb';
  color: $softBlack;
}

.icon-truck::before {
  content: '\e9cd';
  color: $softBlack;
}
