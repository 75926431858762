.lc-theme {
  .actions-flex-row {
    .under-mat-card:first-child {
      width: auto;
      text-align: center;
    }

    .under-mat-card:not(:first-child) {
      @media (max-width: 768px) {
        border-left: 0;
      }

      @media (min-width: 769px) {
        border-left: 1px solid rgba(0 0 0 / 12%);
      }
    }

    .force-border-left {
      @media (min-width: 769px) {
        border-left: 1px solid rgba(0 0 0 / 12%);
      }
    }

    @media (max-width: 768px) {
      .sm .half .lg {
        width: 100% !important;
      }
    }

    @media (min-width: 769px) {
      .sm {
        width: 29% !important;
      }

      .half {
        width: 50% !important;
      }

      .lg {
        width: 42% !important;
      }
    }
  }
}

.lc-square-btn {
  text-decoration: none !important;

  .icon {
    font-size: 1.5rem;

    &::before {
      color: $blueLink;
    }
  }

  .lc-caption {
    max-width: 125px;
    white-space: normal;
  }
}

.upload-area-file-delete {
  .icon {
    font-size: 1.5rem;
  }
}
