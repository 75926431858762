@import 'projects/lib-nova-ui/src/styles/mixins';
@import './colors';

@mixin media-height-up($height) {
  @media (min-height: $height) {
    @content;
  }
}

@mixin media-height-down($height) {
  @media (max-height: $height) {
    @content;
  }
}

@mixin ie-only() {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

/**
  mixin for multiline
  source: http://hackingui.com/front-end/a-pure-css-solution-for-multiline-text-truncation/
*/
/* stylelint-disable */
@mixin multiLineEllipsis(
  $lineHeight: 1.2rem,
  $lineCount: 1,
  $bgColor: white,
  $adjustment: 0
) {
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount + $adjustment;
  text-align: justify;

  &::before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 2px;
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}
/* stylelint-enable */
@mixin icon() {
  /* use !important to prevent issues with browser extensions that change fonts */
  /* stylelint-disable */
  font-family: 'icomoon' !important;
  /* stylelint-enable */
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  // Ne pas oublier de commenter lorsqu'on met a jour les icones
  // line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin full-height() {
  display: flex;
  flex-direction: column;
  flex: 1;

  @include ie-only() {
    display: block;
  }
}

@mixin height-100 {
  min-height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
}
