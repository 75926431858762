@import '../colors';

input::placeholder {
  user-select: none;
}

div.mat-select-value {
  color: $greyBlack;
}

mat-select.selected {
  .mat-select-placeholder {
    color: $greyBlack;
  }
}

.input-wrapper {
  width: 100%;
  margin: 0.5rem 0;

  &.password-input {
    margin: 0.75rem 0;
  }

  .input-form-control {
    width: 100%;
  }

  .mat-form-field-ripple {
    background-color: $softBlack;
  }

  // Valid and not Selected
  color: $greyBlack;

  .mat-input-disabled {
    input {
      color: rgba(0 0 0 / 38%);
    }
    /* stylelint-disable */
    .mat-form-field-underline {
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 1) 0,
        rgba(0, 0, 0, 0.42) 33%,
        transparent 0
      );
      background-size: 4px 100%;
      background-repeat: repeat-x;
      background-color: transparent;
    }
  }
  /* stylelint-enable */
  // Valid and Selected
  .mat-focused {
    color: $greyBlack;
  }
}
