@import '../colors';

.radio-wrapper {
  margin-top: 1rem;

  .radio-label {
    font-size: 13px;
    color: $softBlack;
  }

  .radio-options {
    display: flex;

    &.horizontal {
      flex-direction: row;
    }

    &.vertical {
      flex-direction: column;
    }
  }

  .form-control {
    border: none;
    background-color: transparent;

    // Hide the default radio button
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      // we need to use opacity rather than display: none;
      // or something because the element still needs to
      // exist on the page for us to be able to operate
      // the radio buttons without a mouse
      opacity: 0;
    }

    // Replace it with a custom one
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      position: relative;
      margin-left: -18px; // compensate for opacity
      padding-left: 28px;
      cursor: pointer;
      line-height: 19px;
      display: inline-block;
      color: $softBlack;
      font-weight: 400;
    }

    // Outside circle of the button
    [type="radio"]:checked + label::before,
    [type="radio"]:not(:checked) + label::before {
      content: '';
      position: absolute;
      width: 19px;
      height: 19px;
      top: 0;
      left: 0;
      border: {
        width: 1px;
        style: solid;
        radius: 100%;
      }
    }

    [type="radio"]:checked + label::before {
      @include color($softBlack, $softWhite, $grey);
    }

    [type="radio"]:not(:checked) + label::before {
      @include color($softBlack, $white, $grey);
    }

    // Inside circle of the button
    [type="radio"]:checked + label::after,
    [type="radio"]:not(:checked) + label::after {
      content: '';
      width: 13px;
      height: 13px;
      top: 3px;
      left: 3px;
      background: $softBlack;
      position: absolute;
      border-radius: 100%;
    }

    [type="radio"]:not(:checked) + label::after {
      background: $white;
    }

    [type="radio"]:not(:checked) + label:hover {
      &::before,
      &::after {
        background: $softWhite;
      }
    }
  }
}
