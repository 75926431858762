@import '../colors';

html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: none;
}

.btn {
  font-size: 1rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  height: 2.5rem;
  background: none;
  border: {
    width: 1px;
    style: solid;
    radius: 100px; // radius caps out; we want the maximum possible
  }

  transition: background-color 0.15s ease-in;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
  }

  &.btn-block {
    width: 100%;
  }

  &.btn-primary {
    // This doesn't work in the parent for some reason
    outline: none;

    @include color($white, $actionBlue, $actionBlue);

    &:hover {
      @include color($white, $actionBlueLight, $actionBlueLight);
    }

    &:active {
      @include color($white, $actionBlueDark, $actionBlueDark);
    }

    &:disabled {
      @include color($white, $grey, $grey);
    }
  }

  &.btn-secondary {
    // This doesn't work in the parent for some reason
    outline: none;
    border-width: 2px;

    @include color($greyBlack, $white, $greyBlack);

    &:hover {
      @include color($white, $greyBlack, $greyBlack);
    }

    &:active {
      @include color($white, $black, $black);
    }

    &:disabled {
      @include color($white, $grey, $grey);
    }

    &.btn-inverse {
      &:hover {
        @include color($white, $almostBlack, $white);
      }
    }
  }

  &.btn-default {
    @include color($softBlack, transparent, $softBlack);

    display: flex;
    position: relative;
    border : {
      width: 2px;
    }

    &:hover {
      @include color($white, $grey, $softBlack);
    }

    &:disabled {
      color: $grey;
      border-color: $grey;
      pointer-events: none;
    }

    .icon {
      position: absolute;
      top: 10px;
      left: 25px;
      color: $darkGrey;
    }

    span {
      font-size: 15px;
      opacity: 0.85;
      color: $softBlack;
    }
  }

  &.btn-link {
    color: $linkBlue;
    border: 0;
    outline: none;
  }
}

.btn-transparent {
  background: transparent;
  border: 0;
  outline: none;

  &:focus {
    outline: none;
  }

  &.btn-link {
    color: $blueLink;
    box-shadow: none !important;
    background: transparent !important;

    &:disabled {
      @include color($grey, $grey, $grey);
    }
  }
}

.c-pointer {
  cursor: pointer;
}
