.table {
  max-width: 60rem;
  border: 2px solid $white;
  box-shadow: 3px -2px 12px rgba(0 0 0 / 16%);
  background-color: $white;

  .theader {
    height: 6.25rem;
    background-color: rgba($grey, 0.45);
    display: flex;
    align-items: center;
    padding: 0 2rem 0 1.5rem;

    .title {
      margin-top: -2rem;
      margin-right: 8rem;

      @include media-breakpoint-down(lg) {
        margin-top: 0;
      }
    }

    .right {
      margin-left: auto;
    }
  }

  .tbody {
    padding: 1rem;
  }
}
