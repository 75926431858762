.dialog-panel {
  mat-dialog-container {
    @include media-breakpoint-down(md) {
      padding-top: 2.5rem;
    }
  }

  &.lc-modal-flow {
    mat-dialog-container.mat-dialog-container {
      @include media-breakpoint-down(md) {
        padding-top: 1.5rem;
      }
    }
  }
}

.dialog-bottom-button {
  @include media-breakpoint-down(sm) {
    @include height-100;

    bnv-drug-coverage-letter-dialog-component {
      @include height-100;

      .height-100 {
        @include height-100;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .cdk-overlay-pane.mat-snack-bar-handset {
    padding-bottom: 23%;
  }
}

.modale-notice {
  h1,
  h2,
  b {
    display: block;
    padding: 0 3rem 2.25rem 0;
  }
}
