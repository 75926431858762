/**
* Caution: Add only font settings shared by all nova projects
**/
/* stylelint-disable */
$font-weight-light: 100;
$font-weight-regular: 400;
$font-weight-normal: $font-weight-regular;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$lc-overline: 0.75rem;
$lc-overline-lineheight: 1.0625rem;

$lc-caption: 0.75rem;
$lc-caption-lineheight: 1.0625rem;

$lc-body1: 1rem;
$lc-body1-lineheight: 1.375rem;

$lc-body2: 0.88rem;
$lc-body2-lineheight: 1.25rem;

$lc-subheading: 1.125rem;
$lc-subheading-lineheight: 1.40625rem;

$lc-subheading-small: 0.88rem;
$lc-subheading-small-lineheight: 1.25rem;

$lc-title: 1.625rem;
$lc-title-lineheight: 1.953122rem;

$lc-subtitle: 1rem;
$lc-subtitle-lineheight: 1.375rem;

$lc-subtitle2: 0.875rem;
$lc-subtitle2-lineheight: 1.0625rem;

$lc-headline: 2rem;
$lc-headline-mobile: 1.625rem;
$lc-headline-lineheight: 2.5rem;
/* stylelint-enable */
