@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');
@import url('https://fonts.googleapis.com/css?family=Graduate:400');
@import '../mixins';
@import '../variables';

h1 {
  font-size: 4.25rem;
}

h2 {
  font-size: 1.9375rem;
}

h3 {
  font-weight: 400;
  font-size: 1.37rem;
}

.text-label {
  font-weight: 400;
  font-size: 1rem;
}

.text-small {
  font-size: 0.75rem;
}

.text-big {
  font-size: 1.12rem;
}

.text-error {
  font-size: 0.875rem;
}

.text-primary {
  color: $primary;
}

.nowrap {
  white-space: nowrap;
}

.semi-bold {
  font-weight: 400;
}
