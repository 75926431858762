.checkbox-wrapper {
  .checkbox-label {
    margin-bottom: 0;
  }

  .form-control {
    width: 15px;

    // Hide the default checkbox button
    &:checked,
    &:not(:checked) {
      // we need to use opacity rather than display: none;
      // or something because the element still needs to
      // exist on the page for us to be able to operate
      // the radio buttons without a mouse
      opacity: 0;
      height: 0;
    }

    // Replace it with a custom one
    &:checked + label,
    &:not(:checked) + label {
      position: relative;
      padding-left: 24px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: $softBlack;
      font-size: 0.75rem;
    }

    // Checkbox styling
    &:checked + label::before,
    &:not(:checked) + label::before {
      content: '';
      position: absolute;
      width: 13px;
      height: 13px;
      top: 3px;
      left: 0;
      border: {
        width: 1px;
        style: solid;
      }
    }

    &:checked + label::before {
      @include color($softBlack, $softBlack, $softBlack);
    }

    &:not(:checked) + label::before {
      @include color($softBlack, $white, $grey);
    }

    // Checkmark styling
    &:checked + label::after {
      content: '';
      width: 9px;
      height: 9px;
      top: 6px;
      left: 2px;
      background: url('/assets/img/checkmark.svg') no-repeat;
      background-size: 100%;
      position: absolute;
    }
  }
}
