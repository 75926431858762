/* stylelint-disable */
/**
* Caution: Add only colors shared by all nova projects
**/

/*******************************************
**************** Theme colors***************
********************************************/

$dark: #000;
$white: #fff;

// ********* Palettes régulières *********
$purple: #7f659b;
$pink: #ff88a4;
$brown: #b77e0d;
$olive: #88911a;
$orange: #ffb000;
$yellow: #ffd300;
$coral: #eb6c0b;

// ********** Palettes étendues **********
$lcGray: rgba(237, 237, 237, 40%);
$lcSoftGray: #707070;
$siteBackground: #ededed;
$lcLightGray: rgba(0, 0, 0, 40%);
$lcDarkGray: #666;
$greyBlack: #404040;

// Couleurs primaires
$blue-interaction: #0e73af;
$blue-interaction-light: #e2eef5;
$blue-interaction-dark: #044386;
$blue-interaction-100: #b7d5e7;
$blue-interaction-200: #87b9d7;
$blue-interaction-300: #569dc7;
$blue-interaction-400: #3288bb;
$blue-interaction-600: #0c6ba8;
$blue-interaction-700: #0a609f;
$blue-interaction-800: #085696;

$neutral: #666;
$neutral-light: #ededed;
$neutral-dark: #383838;
$neutral-100: #d1d1d1;
$neutral-200: #b3b3b3;
$neutral-300: #949494;
$neutral-400: #7d7d7d;
$neutral-600: #5e5e5e;
$neutral-700: #535353;
$neutral-800: #494949;

$grey-900: #383838;

$green: #b9d300;
$green-light: #f7fae0;
$green-dark: #93b700;
$green-100: #eaf2b3;
$green-200: #dce980;
$green-300: #cee04d;
$green-400: #c4da26;
$green-600: #b2ce00;
$green-700: #aac800;
$green-800: #a2c200;

$turquoise: #31a5a8;
$turquoise-light: #e6f4f5;
$turquoise-dark: #13797d;
$turquoise-100: #c1e4e5;
$turquoise-200: #98d2d4;
$turquoise-300: #6fc0c2;
$turquoise-400: #50b3b5;
$turquoise-600: #2c9da0;
$turquoise-700: #259397;
$turquoise-800: #1f8a8d;

$blue: #85bae1;
$blue-light: #f0f7fb;
$blue-dark: #5594cd;
$blue-100: #daeaf6;
$blue-200: #c2ddf0;
$blue-300: #aacfea;
$blue-400: #97c4e6;
$blue-600: #7db3dd;
$blue-700: #72abd9;
$blue-800: #68a3d5;

$purple-50: #b394d5; // hover and focus border
$purple-200: #bfb2cd;
$purple-700: #7840b4; // New $action-primary-hover for main menu in EC
$orange-200: #f5b685;

/*******************************************
**************** LC Colors *****************
********************************************/
$lcOrange: #fec234;
$lcOrangeLight: #ffe7b3;
$lcBlue: #5fa1d1;
$lcPink: #ff88a4;
$lcPinkLight: #ffdbe4;
$lcYellow: #fedb3b;
$lcYellowLight: #fff2b3;
$lcYellowDark: #ffd880;
$lcTurquoise: #13797d;
$lcTurquoiseLight: #c1e4e5;
$lcGreen: #cadc36;
$lcGreenLight: #eaf2b3;
$lcTurquoise: #38a4a7;
$lcPurple: #7f659b;
$lcPurpleLight: #d9d1e1;
$lcWarning: #eb670b;
$lcWarningBg: #fff6e0;

//Gradients
$lcGradient: linear-gradient(
  to right,
  #5fa1d1 33%,
  $lcTurquoise 33%,
  $lcTurquoise 66%,
  $lcYellow 66%
);
$lcToGreenGradient: linear-gradient(
  92deg,
  $lcTurquoiseLight 0%,
  $lcGreenLight 100%
);
$lcToBlueGradient: linear-gradient(
  92deg,
  $lcPurpleLight 0%,
  $lcTurquoiseLight 100%
);
$lcToPurpleGradient: linear-gradient(
  92deg,
  $lcPinkLight 0%,
  $lcPurpleLight 100%
);
$lcToYellowGradient: linear-gradient(
  92deg,
  $lcGreenLight 0%,
  $lcYellowLight 100%
);
$lcToOrangeGradient: linear-gradient(
  92deg,
  $lcYellowLight 0%,
  $lcOrangeLight 100%
);
$lcToPinkGradient: linear-gradient(92deg, $lcOrangeLight 0%, $lcPinkLight 100%);

// Actions / Feedback
$actionBlue: #1486be;
$actionBlueLight: #2a9bd2;
$actionBlueDark: #0a6ea1;
$linkBlue: #31a5a8;
$errorRed: #f44336;
$warningOrange: #eb670b;
$softErrorRed: #ffdbe0;
$successGreen: #65a421;
$highlightBlue: #cfe2f1;
$highlightGreen: #b8d02b;
$successGreenLight: #b9d300;
$warningBrown: #856404;
$previousText: #116e9c;
$previousHoverBackground: #e9f4f9;

// White / Grey / Black
$black: #000;
$almostBlack: #272727;
$softBlack: #707070;
$greyMediumLight: #cacaca;
$greyBlack: #404040;
$grey: #d4d4d4;
$darkGrey: #636466;
$softGrey: #e7e7e7;
$lightGrey: #d3d3d3;
$lightGreyFocus: #a1a1a1;
$white: #fff;
$softWhite: #f2f2f2;

// Colors from spec UI/UX in XD (see: https://xd.adobe.com/spec/ef683890-f169-4f97-7ce4-ae2a944c817e-9959/)
$userColor: #85bae1;
$error: #c32231;
$errorBackground: #ffb9b9;
$secondaryButton: #404040;
$currentText: $greyBlack;
$darkSlateGrey: #626262;
$iconBgDarkGrey: #666;
$darkGreyBorder: #666;
$titleBackground: #f3f3f3;
$lightGreyBackground: #f8f8f8;
$backgroundHighlight: #e9f4f9;
$siteBackground: #ededed;
$realInsuranceGreen: #b9d300;
$realInsuranceGreenBackground: #ebf3b9;
$financeTurquoise: #31a5a8;
$hoverLink: #116e9c;
$grey85: #858585;
$greyBorder: #e8e8e8;
$boxBlue: #e2eef5;
$boxBlueBorder: #0e73af;
$blueLink: #0e73af;
$mediumGrayBorder: #ededed;

$lcLightBlue: #e6f4f5;
$lcLightBlueHover: #d2eaeb;
$lcDarkBlue: #5594cd;
$lcGray: rgba(237, 237, 237, 0.4);

$lcLightGray: rgba(0, 0, 0, 0.04);
$lcDisabled: rgba(0, 0, 0, 0.12);
$lcInfo: #f7fae0;
$infoBgGradient: linear-gradient(359deg, #eaf2b3 0%, $lcInfo 100%);
$lcSuccessMessage: #cade3f;
$lcDarkGray: #666;
$lcGreenBackground: #dce980;
$hintBlue: #b7d5e7;

// Fonts Colors
$fontTitle: rgba(0, 0, 0, 0.87);
$fontMedium: rgba(0, 0, 0, 0.75);
$fontHard: #000;
$fontClassic: rgba(0, 0, 0, 0.6);
$blueLink: #0e73af;
$blueLinkHover: #0a6097;

// Puces Colors
$lcPuceBlue: #85bae1;
$lcPuceTurquoise: #98d2d4;
$lcPuceGreen: #cee04d;
$lcPucePurple: #bfb2cd;
$lcPuceOrange: #f5b685;

// Chip
$lcChipBlue: #e3f0f7;
$lcChipBlueHover: #0e73af;
$lcChipOrange: #f55a47;

//flow dialog colors
$progressBarBufferGray: #dcdcdc;

@mixin color($text, $background, $border) {
  color: $text;
  background-color: $background;
  border-color: $border;
}

@mixin placeholderColor($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }

  &::-moz-placeholder {
    color: $color;
  }

  &:-moz-placeholder {
    color: $color;
  }

  &:-ms-input-placeholder {
    color: $color;
  }
}
/* stylelint-enable */
