@import '../colors';

/* stylelint-disable */
@font-face {
  font-family: 'benevaIcon';
  src: url('/assets/fonts/beneva_icon.eot?lcgchh');
  src:
    url('/assets/fonts/beneva_icon.eot?lcgchh#iefix') format('embedded-opentype'),
    url('/assets/fonts/beneva_icon.ttf?lcgchh') format('truetype'),
    url('/assets/fonts/beneva_icon.woff?lcgchh') format('woff'),
    url('/assets/fonts/beneva_icon.svg?lcgchh#products') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='beneva-icon-'],
[class*=' beneva-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'benevaIcon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  // Ne pas oublier de commenter lorsqu'on met a jour les icones
  // line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.beneva-icon-default::before,
.beneva-icon-affa::before,
.beneva-icon-voiture::before {
  content: '\e900';
  color: $softBlack;
}

.beneva-icon-bateau::before {
  content: '\e91c';
  color: $softBlack;
}

.beneva-icon-voiture::before {
  content: '\e900';
  color: $softBlack;
}

.beneva-icon-caravane::before {
  content: '\e91b';
  color: $softBlack;
}

.beneva-icon-coeur::before {
  content: '\e92f';
  color: $softBlack;
}

.beneva-icon-cyclomoteur::before,
.beneva-icon-moto::before  {
  content: '\e916';
  color: $softBlack;
}

.beneva-icon-detaillant::before,
.beneva-icon-garderie::before,
.beneva-icon-coiffeur::before {
  content: '\e939';
  color: $softBlack;
}

.beneva-icon-building::before,
.beneva-icon-locataire::before {
  content: '\e920';
  color: $softBlack;
}

.beneva-icon-mainAvatar::before {
  content: '\e93b';
  color: $softBlack;
}

.beneva-icon-maison::before {
  content: '\e904';
  color: $softBlack;
}

.beneva-icon-motoneige::before {
  content: '\e923';
  color: $softBlack;
}

.beneva-icon-roulotte1::before {
  content: '\e921';
  color: $softBlack;
}

.beneva-icon-toutTerrain::before {
  content: '\e91e';
  color: $softBlack;
}

.beneva-icon-tracteur::before {
  content: '\e932';
  color: $softBlack;
}

.beneva-icon-vehiculesCommerciaux::before {
  content: '\e931';
  color: $softBlack;
}

.beneva-icon-caravane-stationnaire::before {
  content: '\e91a';
  color: $softBlack;
}

.beneva-icon-maison-mobile::before {
  content: '\e91f';
  color: $softBlack;
}

.beneva-icon-rima::before,
.beneva-icon-radm::before,
.beneva-icon-juridique::before,
.beneva-icon-responsabilite-civile::before {
  content: '\e925';
  color: $softBlack;
}

.beneva-icon-hammer::before {
  content: '\e93f';
  color: $softBlack;
}

.beneva-icon-office::before {
  content: '\e93e';
  color: $softBlack;
}

.beneva-icon-garagiste::before {
  content: '\e948';
  color: $softBlack;
}

.beneva-icon-combine::before {
  content: '\e949';
  color: $softBlack;
}

.beneva-icon-ca::before {
  content: '\e943';
  color: $softBlack;
}

.beneva-icon-remorque::before,
.beneva-icon-caravan::before {
  content: '\e930';
  color: $softBlack;
}

.beneva-icon-truck::before {
  content: '\e9cd';
  color: $softBlack;
}
