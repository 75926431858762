@font-face {
  font-family: 'matter-regular';
  src: url('~@lacapitale/beneva-design-system/src/fonts/Matter/Matter-Beneva-Regular.woff2')
      format('woff2'),
    url('~@lacapitale/beneva-design-system/src/fonts/Matter/Matter-Beneva-Regular.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'matter-regular-italic';
  src: url('~@lacapitale/beneva-design-system/src/fonts/Matter/Matter-Beneva-RegularItalic.woff2')
      format('woff2'),
    url('~@lacapitale/beneva-design-system/src/fonts/Matter/Matter-Beneva-RegularItalic.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'matter-light';
  src: url('~@lacapitale/beneva-design-system/src/fonts/Matter/Matter-Beneva-Light.woff2')
      format('woff2'),
    url('~@lacapitale/beneva-design-system/src/fonts/Matter/Matter-Beneva-Light.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'matter-light-italic';
  src: url('~@lacapitale/beneva-design-system/src/fonts/Matter/Matter-Beneva-LightItalic.woff2')
      format('woff2'),
    url('~@lacapitale/beneva-design-system/src/fonts/Matter/Matter-Beneva-LightItalic.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'matter-heavy';
  src: url('~@lacapitale/beneva-design-system/src/fonts/Matter/Matter-Beneva-Heavy.woff2')
      format('woff2'),
    url('~@lacapitale/beneva-design-system/src/fonts/Matter/Matter-Beneva-Heavy.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'matter-heavy-italic';
  src: url('~@lacapitale/beneva-design-system/src/fonts/Matter/Matter-Beneva-HeavyItalic.woff2')
      format('woff2'),
    url('~@lacapitale/beneva-design-system/src/fonts/Matter/Matter-Beneva-HeavyItalic.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'matter-medium';
  src: url('~@lacapitale/beneva-design-system/src/fonts/Matter/Matter-Beneva-Medium.woff2')
      format('woff2'),
    url('~@lacapitale/beneva-design-system/src/fonts/Matter/Matter-Beneva-Medium.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'matter-medium-italic';
  src: url('~@lacapitale/beneva-design-system/src/fonts/Matter/Matter-Beneva-MediumItalic.woff2')
      format('woff2'),
    url('~@lacapitale/beneva-design-system/src/fonts/Matter/Matter-Beneva-MediumItalic.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'matter-semibold';
  src: url('~@lacapitale/beneva-design-system/src/fonts/Matter/Matter-Beneva-SemiBold.woff2')
      format('woff2'),
    url('~@lacapitale/beneva-design-system/src/fonts/Matter/Matter-Beneva-SemiBold.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'matter-semibold-italic';
  src: url('~@lacapitale/beneva-design-system/src/fonts/Matter/Matter-Beneva-SemiBoldItalic.woff2')
      format('woff2'),
    url('~@lacapitale/beneva-design-system/src/fonts/Matter/Matter-Beneva-SemiBoldItalic.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'matter-bold';
  src: url('~@lacapitale/beneva-design-system/src/fonts/Matter/Matter-Beneva-Bold.woff2')
      format('woff2'),
    url('~@lacapitale/beneva-design-system/src/fonts/Matter/Matter-Beneva-Bold.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'matter-bold-italic';
  src: url('~@lacapitale/beneva-design-system/src/fonts/Matter/Matter-Beneva-BoldItalic.woff2')
      format('woff2'),
    url('~@lacapitale/beneva-design-system/src/fonts/Matter/Matter-Beneva-BoldItalic.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'suisse_worksmedium';
  src: url('~@lacapitale/beneva-design-system/src/fonts/Suisse/suisseworks-medium-webfont.woff2')
      format('woff2'),
    url('~@lacapitale/beneva-design-system/src/fonts/Suisse/suisseworks-medium-webfont.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}
