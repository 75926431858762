@import '@lacapitale/beneva-design-system/src/scss/helpers/colors-beneva';

a,
.pointer {
  cursor: pointer;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: $primary;
  }
}

img {
  vertical-align: middle;
}
