@import 'projects/lib-nova-ui/src/styles/mixins.scss';
@import '@lacapitale/beneva-design-system/src/scss/helpers/colors-beneva';

.lc-maintenance-on {
  display: flex;
  background: $primary url('/assets/img/wave-page-error.svg') no-repeat;
  background-position: 17%;
  background-size: 160% auto;

  .bnv-title-on-boarding {
    color: white;
  }
}

.full-maintenance {
  .lc-maintenance-on {
    @include height-100;

    .container {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
    }
  }
}
