@import "../colors";

/* stylelint-disable */
@font-face {
  font-family: 'coverages-po';
  src: url('/assets/fonts/iconespo.eot?1octla');
  src:
    url('/assets/fonts/iconespo.eot?1octla#iefix') format('embedded-opentype'),
    url('/assets/fonts/iconespo.ttf?1octla') format('truetype'),
    url('/assets/fonts/iconespo.woff?1octla') format('woff'),
    url('/assets/fonts/iconespo.svg?1octla#iconespo') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="coverages-po-"],
 [class*=" coverages-po-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'coverages-po' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  // Ne pas oublier de commenter lorsqu'on met a jour les icones
  // line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.coverages-po-po2::before {
  content: "\e900";
}

.coverages-po-po3::before {
  content: "\e901";
}

.coverages-po-po4::before {
  content: "\e902";
}

.coverages-po-po5::before {
  content: "\e903";
}

.coverages-po-po6::before {
  content: "\e904";
}

.coverages-po-po7-fr::before {
  content: "\e905";
}

.coverages-po-po7-en::before {
  content: "\e906";
}

.coverages-po-po8::before {
  content: "\e907";
}

.coverages-po-po9::before {
  content: "\e908";
}

.coverages-po-po10::before {
  content: "\e909";
}

.coverages-po-po11::before {
  content: "\e90a";
}

.coverages-po-po12::before {
  content: "\e90b";
}

.coverages-po-po13::before {
  content: "\e90c";
}

.coverages-po-po14::before {
  content: "\e90d";
}

.coverages-po-po15::before {
  content: "\e90e";
}

.coverages-po-po16::before {
  content: "\e90f";
}

.coverages-po-po17::before {
  content: "\e910";
}

.coverages-po-po18::before {
  content: "\e911";
}

.coverages-po-po19::before {
  content: "\e912";
}

.coverages-po-po58::before {
  content: "\e913";
}

.coverages-po-po59::before {
  content: "\e914";
}

.coverages-po-po20::before {
  content: "\e915";
}

.coverages-po-po21-fr::before {
  content: "\e9016";
}

.coverages-po-po21-en::before {
  content: "\e917";
}

.coverages-po-po22::before {
  content: "\e918";
}

.coverages-po-po23::before {
  content: "\e919";
}

.coverages-po-po24::before {
  content: "\e91a";
}

.coverages-po-po25::before {
  content: "\e91b";
}

.coverages-po-po1::before {
  content: "\e91c";
}

.coverages-po-po26::before {
  content: "\e91d";
}

.coverages-po-po27::before {
  content: "\e91e";
}

.coverages-po-po28::before {
  content: "\e91f";
}

.coverages-po-po29::before {
  content: "\e920";
}

.coverages-po-po30::before {
  content: "\e921";
}

.coverages-po-po31::before {
  content: "\e922";
}

.coverages-po-po32::before {
  content: "\e923";
}

.coverages-po-po33::before {
  content: "\e924";
}

.coverages-po-po34::before {
  content: "\e925";
}

.coverages-po-po35::before {
  content: "\e926";
}

.coverages-po-po36::before {
  content: "\e927";
}

.coverages-po-po37::before {
  content: "\e928";
}

.coverages-po-po38::before {
  content: "\e929";
}

.coverages-po-po39::before {
  content: "\e92a";
}

.coverages-po-po40::before {
  content: "\e92b";
}

.coverages-po-po41::before {
  content: "\e92c";
}

.coverages-po-po42::before {
  content: "\e92d";
}

.coverages-po-po43::before {
  content: "\e92e";
}

.coverages-po-po44::before {
  content: "\e92f";
}

.coverages-po-po45::before {
  content: "\e930";
}

.coverages-po-po46::before {
  content: "\e931";
}

.coverages-po-po47::before {
  content: "\e932";
}

.coverages-po-po48::before {
  content: "\e933";
}

.coverages-po-po49::before {
  content: "\e934";
}

.coverages-po-po50::before {
  content: "\e935";
}

.coverages-po-po51::before {
  content: "\e936";
}

.coverages-po-po52::before {
  content: "\e937";
}

.coverages-po-po53::before {
  content: "\e938";
}

.coverages-po-po54::before {
  content: "\e939";
}

.coverages-po-po55::before {
  content: "\e93a";
}

.coverages-po-po56::before {
  content: "\e93b";
}

.coverages-po-po57::before {
  content: "\e93c";
}

.coverages-po-po60::before {
  content: "\e943";
}

.coverages-po-po61::before {
  content: "\e944";
}
