$header-desktop-height: 4.8rem;
$header-desktop-enterprise-links: 3.125rem;
$extended-header: 7.925rem;
$header-mobile-height: 4.6rem;
$footer-mobile-height: 3rem;
$footer-height: 2.81rem;
$feedback-banner-height: 5.5rem;
$sub-menu-height: 7.813rem;

$footer-height-iphone-notch: 1.81rem;

$h-xs: 27.5rem;
$h-sm: 40rem;
$h-md: 48.125rem;
$h-lg: 57.5rem;
$min-height: 30rem;
