@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import './base';
@import './main-styles';
@import './dialog';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/forms';
@import './reset';
@import './main/button';
@import './main/card';
@import './main/checkbox';
@import './main/dialog';
@import './main/divider';
@import './main/icomoon';
@import './main/input';
@import './main/layout';
@import './main/menu';
@import './main/radio';
@import './main/scrollbar';
@import './main/table';
@import './main/typography';
@import 'css-star-rating/css/star-rating.min.css';

// New UX
@import './version2/material_theme';
@import './version2/grid';
@import './version2/input';

// End new UX

@import './locale-style/maintenance';
@import './locale-style/home';
@import './locale-style/header';
@import './locale-style/reclaim-info';

// Hide Recaptcah V3 Badge
.grecaptcha-badge {
  visibility: collapse !important;
}
