@import 'projects/lib-nova-ui/src/styles/colors.scss';
/* stylelint-disable */
// Tonic
$insuranceGreen: #31a5a8;
$financeGreen: #b9d300;

//Badges
$badgeYello: #fff3b9;
$badgeYelloBorder: #ffd300;

$badgeGreen: #ebf3b9;
$badgeGreenBorder: #88911a;

// Beta
$lcBetaBg: #f0f7fb;
$lcGrayBlack: #494949;
$lcSubMenuGray: #404040;
$lcBetaGray: rgba(255, 255, 255, 0.16);
$lcBlackBlue: #000000de;
$lcGrayLight: #d1d1d1;
/* stylelint-enable */
