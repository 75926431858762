/**
* Caution: This file shouldn't be imported by any component
**/
@import '../base';

.lc-container {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
}

.lc-theme {
  .row-no-gutters {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -16px;
    margin-left: -16px;
  }

  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }

  /* Padding */
  .p-0 {
    padding: 0 !important;
  }

  .p-1 {
    padding: lc-spacing(1) !important;
  }

  .p-2 {
    padding: lc-spacing(2) !important;
  }

  .p-3 {
    padding: lc-spacing(3) !important;
  }

  .p-4 {
    padding: lc-spacing(4) !important;
  }

  @include media-breakpoint-down(sm) {
    .p-4 {
      padding: lc-spacing(2) !important;
    }
  }

  .p-5 {
    padding: lc-spacing(5) !important;
  }

  .p-6 {
    padding: lc-spacing(6) !important;
  }

  .pt-0 {
    padding-top: lc-spacing(0) !important;
  }

  .pt-1 {
    padding-top: lc-spacing(1) !important;
  }

  .pt-2 {
    padding-top: lc-spacing(2) !important;
  }

  .pt-3 {
    padding-top: lc-spacing(3) !important;
  }

  .pt-4 {
    padding-top: lc-spacing(4) !important;
  }

  .pt-5 {
    padding-top: lc-spacing(5) !important;
  }

  .pt-6 {
    padding-top: lc-spacing(6) !important;
  }

  .pb-0 {
    padding-bottom: 0 !important;
  }

  .pb-1 {
    padding-bottom: lc-spacing(1) !important;
  }

  .pb-2 {
    padding-bottom: lc-spacing(2) !important;
  }

  .pb-3 {
    padding-bottom: lc-spacing(3) !important;
  }

  .pb-4 {
    padding-bottom: lc-spacing(4) !important;
  }

  .pb-5 {
    padding-bottom: lc-spacing(5) !important;
  }

  .pb-6 {
    padding-bottom: lc-spacing(6) !important;
  }

  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-1 {
    padding-top: lc-spacing(1) !important;
    padding-bottom: lc-spacing(1) !important;
  }

  .py-2 {
    padding-top: lc-spacing(2) !important;
    padding-bottom: lc-spacing(2) !important;
  }

  .py-3 {
    padding-top: lc-spacing(3) !important;
    padding-bottom: lc-spacing(3) !important;
  }

  .py-4 {
    padding-top: lc-spacing(4) !important;
    padding-bottom: lc-spacing(4) !important;
  }

  .py-5 {
    padding-top: lc-spacing(5) !important;
    padding-bottom: lc-spacing(5) !important;
  }

  .py-6 {
    padding-top: lc-spacing(6) !important;
    padding-bottom: lc-spacing(6) !important;
  }

  .pl-0 {
    padding-left: 0 !important;
  }

  .pl-1 {
    padding-left: lc-spacing(1) !important;
  }

  .pl-2 {
    padding-left: lc-spacing(2) !important;
  }

  .pl-3 {
    padding-left: lc-spacing(3) !important;
  }

  .pl-4 {
    padding-left: lc-spacing(4) !important;
  }

  .pl-5 {
    padding-left: lc-spacing(5) !important;
  }

  .pl-6 {
    padding-left: lc-spacing(6) !important;
  }

  .pr-0 {
    padding-right: 0 !important;
  }

  .pr-1 {
    padding-right: lc-spacing(1) !important;
  }

  .pr-2 {
    padding-right: lc-spacing(2) !important;
  }

  .pr-3 {
    padding-right: lc-spacing(3) !important;
  }

  .pr-4 {
    padding-right: lc-spacing(4) !important;
  }

  .pr-5 {
    padding-right: lc-spacing(5) !important;
  }

  .pr-6 {
    padding-right: lc-spacing(6) !important;
  }

  .px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-1 {
    padding-left: lc-spacing(1) !important;
    padding-right: lc-spacing(1) !important;
  }

  .px-2 {
    padding-left: lc-spacing(2) !important;
    padding-right: lc-spacing(2) !important;
  }

  .px-3 {
    padding-left: lc-spacing(3) !important;
    padding-right: lc-spacing(3) !important;
  }

  .px-4 {
    padding-left: lc-spacing(4) !important;
    padding-right: lc-spacing(4) !important;
  }

  .px-5 {
    padding-left: lc-spacing(5) !important;
    padding-right: lc-spacing(5) !important;
  }

  .px-6 {
    padding-left: lc-spacing(6) !important;
    padding-right: lc-spacing(6) !important;
  }

  /* End Padding */

  /* Margin */
  .m-0 {
    margin: 0 !important;
  }

  .m-1 {
    margin: lc-spacing(1) !important;
  }

  .m-2 {
    margin: lc-spacing(2) !important;
  }

  .m-3 {
    margin: lc-spacing(3) !important;
  }

  .m-4 {
    margin: lc-spacing(4) !important;
  }

  @include media-breakpoint-down(sm) {
    .m-4 {
      margin: lc-spacing(2) !important;
    }
  }

  .m-5 {
    margin: lc-spacing(5) !important;
  }

  .m-6 {
    margin: lc-spacing(6) !important;
  }

  .mt-0 {
    margin-top: 0 !important;
  }

  .mt-1 {
    margin-top: lc-spacing(1) !important;
  }

  .mt-2 {
    margin-top: lc-spacing(2) !important;
  }

  .mt-3 {
    margin-top: lc-spacing(3) !important;
  }

  .mt-4 {
    margin-top: lc-spacing(4) !important;
  }

  .mt-5 {
    margin-top: lc-spacing(5) !important;
  }

  .mt-6 {
    margin-top: lc-spacing(6) !important;
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }

  .mb-1 {
    margin-bottom: lc-spacing(1) !important;
  }

  .mb-2 {
    margin-bottom: lc-spacing(2) !important;
  }

  .mb-3 {
    margin-bottom: lc-spacing(3) !important;
  }

  @include media-breakpoint-up(sm) {
    .mb-sm-2 {
      margin-bottom: lc-spacing(2) !important;
    }
  }

  .mb-4 {
    margin-bottom: lc-spacing(4) !important;
  }

  .mb-5 {
    margin-bottom: lc-spacing(5) !important;
  }

  .mb-6 {
    margin-bottom: lc-spacing(6) !important;
  }

  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-1 {
    margin-top: lc-spacing(1) !important;
    margin-bottom: lc-spacing(1) !important;
  }

  .my-2 {
    margin-top: lc-spacing(2) !important;
    margin-bottom: lc-spacing(2) !important;
  }

  .my-3 {
    margin-top: lc-spacing(3) !important;
    margin-bottom: lc-spacing(3) !important;
  }

  .my-4 {
    margin-top: lc-spacing(4) !important;
    margin-bottom: lc-spacing(4) !important;
  }

  @include media-breakpoint-down(sm) {
    .my-4 {
      margin-top: lc-spacing(2) !important;
      margin-bottom: lc-spacing(2) !important;
    }
  }

  .my-5 {
    margin-top: lc-spacing(5) !important;
    margin-bottom: lc-spacing(5) !important;
  }

  .my-6 {
    margin-top: lc-spacing(6) !important;
    margin-bottom: lc-spacing(6) !important;
  }

  .ml-0 {
    margin-left: 0 !important;
  }

  .ml-1 {
    margin-left: lc-spacing(1) !important;
  }

  .ml-2 {
    margin-left: lc-spacing(2) !important;
  }

  .ml-3 {
    margin-left: lc-spacing(3) !important;
  }

  .ml-4 {
    margin-left: lc-spacing(4) !important;
  }

  .ml-5 {
    margin-left: lc-spacing(5) !important;
  }

  .ml-6 {
    margin-left: lc-spacing(6) !important;
  }

  .mr-0 {
    margin-right: 0 !important;
  }

  .mr-1 {
    margin-right: lc-spacing(1) !important;
  }

  .mr-2 {
    margin-right: lc-spacing(2) !important;
  }

  .mr-3 {
    margin-right: lc-spacing(3) !important;
  }

  .mr-4 {
    margin-right: lc-spacing(4) !important;
  }

  @include media-breakpoint-down(sm) {
    .mr-4 {
      margin-right: lc-spacing(2) !important;
    }
  }

  .mr-5 {
    margin-right: lc-spacing(5) !important;
  }

  .mr-6 {
    margin-right: lc-spacing(6) !important;
  }

  .mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-1 {
    margin-left: lc-spacing(1) !important;
    margin-right: lc-spacing(1) !important;
  }

  .mx-2 {
    margin-left: lc-spacing(2) !important;
    margin-right: lc-spacing(2) !important;
  }

  .mx-3 {
    margin-left: lc-spacing(3) !important;
    margin-right: lc-spacing(3) !important;
  }

  .mx-4 {
    margin-left: lc-spacing(4) !important;
    margin-right: lc-spacing(4) !important;
  }

  @include media-breakpoint-down(sm) {
    .mx-4 {
      margin-left: lc-spacing(2) !important;
      margin-right: lc-spacing(2) !important;
    }
  }

  .mx-5 {
    margin-left: lc-spacing(5) !important;
    margin-right: lc-spacing(5) !important;
  }

  .mx-6 {
    margin-left: lc-spacing(6) !important;
    margin-right: lc-spacing(6) !important;
  }
}
