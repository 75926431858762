a,
button,
[tabindex='0'] {
  &:focus {
    outline: none;
  }
}

.show-focus-outline {
  a,
  button {
    &:focus {
      outline: 2px solid $softBlack !important;
    }
  }

  #header-container-authenticated a,
  #header-container-authenticated button,
  #header-container-unauthenticated a,
  #header-container-unauthenticated button,
  #footer-home a,
  .main-footer.bg-violet a {
    &:focus {
      outline: 2px solid $lightGreyFocus !important;
    }
  }

  a.mat-button-base,
  button.mat-button-base,
  button.bnv-button-border {
    &:focus {
      outline-offset: 5px;
    }
  }
}
