@import 'projects/nova-personal/src/styles/base';

// firefox css selector
// firefox doesn't handle well table vertical centering
@-moz-document url-prefix() {
  .card-container {
    display: flex !important;
    flex: 1;
    align-items: center;
  }
}

.card-container {
  min-height: calc(100vh - #{$footer-height} - #{$header-desktop-height});
  display: table;
  width: 100%;

  .card-wrapper {
    padding: 5rem 0.625rem 0.625rem;
    width: 100%;
    display: table-cell;
    vertical-align: middle;

    &.include-image {
      position: relative;
      margin-top: 2.7125rem;

      .card-top {
        padding-top: 3.5rem;
      }
    }

    .card {
      padding: 0.3125rem;
      box-shadow: 0 0 12px $grey;
      background-color: rgba($white, 0.9);
      position: relative;
      margin: 0 auto;
      display: block;

      .button-left {
        position: absolute;
        top: calc(50% - 2.625rem);
        left: -5rem;
      }

      max-width: 35rem;
      width: auto;

      &.large {
        max-width: 35rem;
        width: auto;
      }

      &.small {
        max-width: 22.5rem;
        width: auto;
      }

      &.card-margin-desktop-s,
      .card-margin-desktop-s {
        padding: {
          left: 1.375rem;
          right: 1.375rem;
        };

        .button-left {
          left: -2.375rem;
        }
      }

      &.card-margin-desktop-l,
      .card-margin-desktop-l {
        padding: {
          left: 2.5rem;
          right: 2.5rem;
        };

        .button-left {
          left: -4.5rem;
        }
      }

      @include media-breakpoint-up(md) {
        width: 32rem;

        &.large {
          width: 35rem;
        }

        &.card-margin-desktop-s,
        .card-margin-desktop-s {
          padding: {
            left: 1.375rem;
            right: 1.375rem;
          };

          .button-left {
            left: -1.375rem;
          }
        }

        &.card-margin-desktop-l,
        .card-margin-desktop-l {
          padding: {
            left: 4.6rem;
            right: 4.6rem;
          };

          .button-left {
            left: -4.3075rem;
          }
        }
      }

      .title {
        text-align: center;
        font-size: 1.2rem;
        font-weight: bold;
        padding-top: 1.625rem;
        line-height: 1.5rem;
        margin-bottom: 1rem;

        .subtitle {
          font-size: 1rem;
          font-weight: normal;
          margin: 1rem 0;
          line-height: 1.4rem;
          opacity: 0.8;
        }
      }

      .card-push-down-content {
        padding-top: 3rem;
        overflow: hidden;
      }

      .card-body {
        padding-bottom: 1rem;

        .text-small {
          @include media-breakpoint-up(lg) {
            font-size: 0.8125rem;
          }
        }

        .text-description {
          margin-bottom: 1.25rem;
        }

        .helper-text {
          text-align: center;
          margin-bottom: 0.5rem;
        }

        .row-fullwidth {
          width: 100%;
        }

        .margin-wrapper {
          width: 65%;
          margin: auto;
        }
      }

      .card-footer {
        text-align: center;
        margin-top: 1.625rem;
        margin-bottom: 3rem;

        &.flex {
          display: flex;
        }
      }
    }
  }
}

.center {
  text-align: center;
}

.form-group {
  margin-bottom: 0;
}
