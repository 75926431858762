@import 'projects/nova-personal/src/styles/base';

.module-container {
  border-radius: 1.125rem;
  max-width: 100vw;
  flex: 0 0 auto;
  margin-bottom: 0.5rem;
}

.finance {
  .header-desktop {
    background-image: url('/assets/img/home/beneva/finance@2x.png');
  }

  .header-mobile {
    background-image: url('/assets/img/home/beneva/finance.png');
  }
}

.insurance {
  .header-desktop {
    background-image: url('/assets/img/home/beneva/assurance-vie@2x.png');
  }

  .header-mobile {
    background-image: url('/assets/img/home/beneva/assurance-vie.png');

    @media screen and (min-height: 600px) {
      background-image: url('/assets/img/home/beneva/assurance-vie@2x.png');
    }
  }
}

.product-list {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    margin-bottom: 0.4rem;
  }

  mat-icon {
    margin: 0 0.75rem 0 0;
  }

  a {
    text-decoration: none;
  }
}

.module-card {
  background: #fff;

  .content {
    padding: 0.75rem 0.75rem 0.75rem 0.875rem;

    @media screen and (min-height: 500px) and (min-width: 576px) {
      padding: 0.75rem 1rem 0.75rem 1.5rem;
    }
  }

  .header-desktop {
    position: relative;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.caption-tuile {
  padding-top: 0.6rem;
}

.desktop-module {
  &.first {
    .module-container {
      margin-left: 5rem;
    }
  }

  .module-container {
    margin-left: 1.875rem;
    height: 30rem;
    box-shadow: none;
    width: 21.875rem;

    .title-tuile {
      font-size: 1.875rem;
      line-height: 1.875rem;
    }

    @include media-height-up(600px) {
      min-height: 35rem;
      width: 23rem;

      .title-tuile {
        font-size: 2.625rem;
        line-height: 2.625rem;
        margin: 0 0 1.125rem;
      }
    }
  }

  .header-desktop {
    height: 46%;
    background-position: 50% 100%;
    border-radius: 1.125rem 1.125rem 0 0;

    @include media-breakpoint-down(sm) {
      height: 25%;
    }
  }

  .module-footer {
    margin-right: 5rem;
  }
}

.mobile-module {
  position: relative;
  width: 100vw;
  height: 100%;
  padding: 0.5rem 0.5rem 2.5rem;

  @media screen and (min-height: 667px) {
    padding-bottom: 3.6rem;
  }

  .module-container {
    width: 100%;
    height: 100%;
    margin: 0;
  }

  .title-tuile {
    font-size: 1.5rem;
    line-height: 1.5rem;

    @media screen and (min-height: 500px) {
      font-size: 1.875rem;
      line-height: 1.875rem;
      width: 75%;
    }
  }

  .header-mobile {
    height: 30%;
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 1.125rem 1.125rem 0 0;
    margin: 0;

    @media screen and (min-height: 500px) {
      height: 40%;
    }
  }
}

// page detention

body {
  .info-section {
    .mat-expansion-panel {
      .mat-expansion-panel-header {
        padding: 0;
        min-height: 1.5rem;
        display: inline-block;

        &:hover:not([aria-disabled='true']) {
          background: none;
        }

        .mat-expansion-indicator {
          display: none;
        }
      }

      .mat-expansion-panel-body {
        padding: 0 0 1rem;
      }
    }
  }
}

// Notices

.bnv-title-simple-dark-18 {
  color: $title-table;
  font-family: 'matter-medium', sans-serif; // TODO; use $matter-medium
  font-size: $_18 !important;
  line-height: $_18 !important;
  letter-spacing: -0.02rem;
}

.ml-notice {
  margin-left: -32px !important;
  padding-top: 1rem;
}

// Spacing

.p-16 {
  padding: $_16 !important;
}

.bnv-text-box-info {
  width: 42rem;
  max-width: 100%;
}
