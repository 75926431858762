@import 'projects/nova-personal/src/styles/base';
@import 'projects/nova-personal/src/styles/mixins';

.progress-bar {
  top: $header-desktop-height;
  position: absolute;
  width: 100%;
}

body.is-sidenav-opened {
  // prevent scrolling on android
  position: relative;
  overflow-y: hidden;
}

.component-container {
  min-height: calc(100% - #{$footer-height});
}

// firefox css selector
// firefox doesn't handle well table vertical centering
/* stylelint-disable */
@-moz-document url-prefix() {
  .main-container:not(.home) {
    & > router-outlet {
      flex: 0;
      height: auto;
    }

    & > * {
      width: 100%;
    }
  }
}
/* stylelint-enable */
.main-container {
  position: relative;
  padding-top: $header-desktop-height;

  @include height-100;

  &-extended {
    padding-top: $extended-header;
  }

  background-repeat: no-repeat;
  background-position-x: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  background-size: auto 100%;

  @include media-breakpoint-down(md) {
    overflow-x: initial;
    overflow-y: initial;
  }

  & > router-outlet ~ * {
    @include height-100;
  }
}

lc-footer {
  width: 100%;
  height: auto !important;
  overflow-x: hidden;
  min-height: fit-content;
}

.ng-animating ~ lc-footer > .main-footer,
.ng-animating > lc-footer > .main-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  @media (max-height: 850px) {
    display: none;
  }
}

.hide-if-too-small {
  display: none;
}

.toast-container {
  position: fixed;
  top: 4.8rem;
  width: 37.5rem;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 1000;

  @include media-breakpoint-down(sm) {
    top: 3.75rem;
    width: auto;
  }
}

.flex-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lc-app-entreprise {
  lc-header {
    .main-header {
      &#header-container-authenticated {
        .header-right {
          display: none !important;
        }
      }
    }
  }
}
