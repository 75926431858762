@import '../base';
@import '../mixins';
@import 'projects/nova-personal/src/styles/colors';

.dark-backdrop {
  background: rgba($softBlack, 0.1) !important;
}

.contained-backdrop {
  &,
  ~ .cdk-global-overlay-wrapper {
    top: $header-desktop-height;
    bottom: 0;

    &-extended {
      top: $extended-header;
    }
  }
}

.menu-overlay-panel {
  position: absolute !important;
  top: 0;
  right: 0;
  padding: 1.5rem;
  background: $white;

  &.user-panel {
    width: 18rem;
  }
}
