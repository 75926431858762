/**
* Caution: This file shouldn't be imported by any component
**/
@import '../base';
@import 'projects/lib-nova-ui/src/styles/input';

.lc-theme {
  ::ng-deep {
    .cdk-overlay-container {
      z-index: 6000;
    }
  }

  hr {
    opacity: 0.2;
  }

  .lc-btn {
    font-size: 0.875rem;
    height: 40px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;

    &:disabled,
    &:disabled:hover {
      background-color: rgba(0, 0, 0, 0.12) !important;
    }
  }

  .lc-btn-large {
    padding: 0 lc-spacing(3);
  }

  .lc-btn-action {
    color: white;
    background-color: $blueLink;
    text-decoration: none;

    &:hover {
      background-color: $blueLinkHover;
    }

    ::ng-deep {
      .mat-ripple-element {
        background-color: rgba(255, 255, 255, 0.16);
      }
    }
  }

  .lc-btn-secondary,
  .lc-btn-secondary.mat-stroked-button:not([disabled]) {
    color: $fontMedium;
    border: 2px solid $lcGrayBlack;
    background-color: transparent;

    &:hover {
      color: white;
      text-decoration: none;
      background-color: $lcGrayBlack;
    }

    ::ng-deep {
      .mat-ripple-element {
        background-color: rgba(255, 255, 255, 0.16);
      }
    }
  }

  .lc-overline-white-background {
    background-color: $white;
    width: auto;
    min-height: 20px;
    padding: 2px 4px;
    border-radius: 3px;
  }

  .lc-box {
    box-shadow: 0 3px 4px 0 rgba($black, 0.14);
    border-radius: 5px;
  }

  .lc-square-btn {
    text-decoration: none;

    .icon {
      font-size: 1.5rem;

      &::before {
        color: $blueLink;
      }
    }

    .lc-caption {
      max-width: 125px;
      white-space: normal;
    }
  }

  .under-mat-card,
  .lc-square-btn {
    ::ng-deep {
      .mat-button-focus-overlay {
        background-color: $blueLink;
      }

      .mat-ripple-element {
        background-color: rgba(14, 115, 175, 0.12);
      }
    }
  }

  /* RADIO BUTTON */
  .lc-radio-btn {
    ::ng-deep {
      .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
        border-color: $blueLink;
      }

      .mat-radio-button.mat-accent .mat-radio-inner-circle {
        background-color: $blueLink;
      }
    }
  }

  .actions {
    display: flex;

    .under-mat-card:first-child {
      border-bottom-right-radius: 0 !important;
      width: 100%;
      text-align: center;
    }

    .under-mat-card:only-child {
      border-bottom-right-radius: 4px !important;
    }

    .under-mat-card:not(:first-child) {
      border-left: 1px solid rgba(0, 0, 0, 0.12);
      border-bottom-left-radius: 0 !important;
    }

    .under-mat-card {
      margin: 0 !important;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }

    lc-product-detail-link {
      .under-mat-card {
        border-bottom-left-radius: 0 !important;
      }
    }
  }

  .link-role {
    padding: 0 !important;
    text-align: left;
    text-decoration: none;

    .mat-button-focus-overlay {
      background: transparent !important;
    }

    .mat-ripple {
      display: none;
    }
  }

  /* SUB MENU */
  .lc-submenu {
    .mat-tab-header {
      background-color: white !important;
      border-bottom: none !important;
    }

    .mat-ink-bar {
      background-color: $lcSubMenuGray !important;
      height: 3px;
    }

    .mat-tab-label-container {
      width: 100%;
      padding-right: lc-spacing(2) !important;
      padding-left: lc-spacing(2) !important;
      margin-right: auto;
      margin-left: auto;

      .mat-tab-label {
        opacity: 1;
        height: auto;
        padding-top: lc-spacing(1);
        padding-bottom: lc-spacing(1);
        cursor: pointer;

        &:not(.mat-tab-label-active):hover {
          background-color: rgba(0, 0, 0, 0.04);
        }

        .mat-tab-label-content {
          color: $fontClassic;
          pointer-events: none;
        }
      }

      .mat-tab-label-active {
        .mat-tab-label-content {
          color: $fontHard;
        }
      }
    }

    @media (max-width: 575px) {
      .bg-white h1 {
        font-size: 1.625rem !important;
        line-height: 1.9375rem !important;
      }
    }

    @media (min-width: 768px) {
      .mat-tab-label-container {
        max-width: 720px;
      }
    }

    @media (min-width: 992px) {
      .mat-tab-label-container {
        max-width: 960px;
      }
    }

    @media (min-width: 1200px) {
      .mat-tab-label-container {
        max-width: 1140px;
      }
    }
  }

  button.link {
    background: none;
    border: none;
  }

  a.navigation-anchor {
    font-size: $lc-body2;
    color: $fontMedium;
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: none;
    }

    i {
      font-size: 1.5rem;
      padding-right: lc-spacing(1);
    }
  }

  .lc-telephone {
    font-size: $lc-subheading;
    line-height: $lc-subheading-lineheight;
    color: $blueLink;
  }
}
